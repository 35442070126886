<template>
  <div class="edit-court m-0 px-3"
       :class="{'vx-card main-box top-zero-radius': !courtId, 'no-box-shadow h-100': courtId}">
    <div class="edit-court-fields">
      <div class="select-user-invoice logo-input">
        <custom-file-input :label="$t('courts.labels.image')"
                           :is-empty="true"
                           :classes="{'preview-image': true}"
                           logo-type="scoreCategory"
                           :styles="{width: '100%'}"
                           v-model="court.logo"
                           :disabled="false"/>

      </div>

      <custom-validate-input :label="$t('courts.labels.name')"
                             :classes="{'w-full': true}"
                             align="right"
                             rtl
                             v-model="court.name"/>

      <vs-button id="deleteUserBTN"
                 class="w-full mt-3"
                 color="danger"
                 v-if="checkUserPermissions('court.delete')"
                 @click="$refs.deleteConfirmation.showDialog()">
        {{ $t('courts.labels.delete') }}
      </vs-button>

      <custom-dialog ref="deleteConfirmation"
                     :title="$t('courts.confirmations.delete.title')"
                     :body="$t('courts.confirmations.delete.body', {name: court.name.value})"
                     @accept="deleteCourt"/>

      <vs-button v-show="false"
                 id="EditCourtBTN"
                 @click="sendData"/>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomValidateInput from '../../../../../components/customInput/customValidateInput.vue'
  import CustomDialog from '../../../../../components/customDialog/customDialog.vue'
  import CustomFileInput from "../../../../../components/customInput/customeFileInput.vue";
  import {deleteCourt, editCourt, getCourt} from '../../../../../http/requests/club/courts'
  import {checkUserPermissions} from '../../../../../assets/js/functions'

  export default {
    name: 'editCourt',
    components: {
      CustomFileInput,
      CustomDialog,
      CustomValidateInput
    },
    props: {
      courtId: {
        type: [Number, String],
        default: 0
      }
    },
    data() {
      return {
        courtTypes: [
          {
            id: 2,
            label: this.$t('courts.types.ground'),
            value: 2
          },
          {
            id: 1,
            label: this.$t('courts.types.hard'),
            value: 1
          },
          {
            id: 3,
            label: this.$t('courts.types.grass'),
            value: 3
          }
        ],
        court: {
          name: {
            value: '',
            isValid: true
          },
          type: {
            label: '-',
            id: -1
          },
          logo: {}
        },
        actions: {
          toolbar: [
            {
              id: 'EditCourtBTN',
              icon: 'SAVE',
              iconPack: 'useral',
              color: 'success',
              permission: 'court.update'
            }
          ],
          leftToolbar: [
            {
              id: 'routeBackBTN',
              route: {name: this.$route.name === 'editCourt' ? 'court' : 'courts'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      }
    },
    created() {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      }, 50)
      this.getCourt()
    },
    methods: {
      getCourt() {
        getCourt(this.courtId || this.$route.params.id).then(response => {
          const court = response.data.data

          let courtType = ''
          if (court.type === 1) {
            courtType = this.$t('courts.types.hard')
          } else if (court.type === 2) {
            courtType = this.$t('courts.types.ground')
          } else if (court.type === 3) {
            courtType = this.$t('courts.types.grass')
          }
          this.court.name.value = court.name || ''
          // this.court.image.value = court.avatar || ''
          this.court.type = {
            label: courtType,
            id: court.type
          }
          this.court.logo = {
            value: court.avatar || '',
            isValid: true
          }
          this.$store.dispatch('setPageTitle', this.$t('courts.edit.dynamicTitle', {name: court.name}))

        }).catch(() => {
        })
      },
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      deleteCourt() {
        deleteCourt(this.courtId || this.$route.params.id).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('courts.notifications.delete.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })
          this.$router.push({name: 'courts'})
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('courts.notifications.delete.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
      },
      sendData() {
        if (!this.court.name.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('courts.validators.name'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        } else if (this.court.type.id === -1) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('courts.validators.type'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        const court = {
          name: this.court.name.value,
          type: parseInt(this.court.type.id) > -1 ? this.court.type.id : null,
          avatar: this.court.logo.value ? this.court.logo.value : null
        }

        if (this.court.role && this.court.role.value > 0 && this.courtId !== this.$store.state.auth.court.id) court.role_id = this.court.role.value

        editCourt(this.courtId || this.$route.params.id, court).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('courts.notifications.edit.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })
          this.$emit('edit')

          this.$router.push({name: 'courts'})
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {

            const error_mapper = {
              'name': this.$t('courts.notifications.parseError.name'),
              'type': this.$t('courts.notifications.parseError.type'),
              'avatar': this.$t('courts.notifications.parseError.image'),
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('courts.notifications.edit.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
.edit-court {

  .edit-court-fields {
    width: 100%;
    max-width: 400px;

    .logo-input {
      min-height: 100px;
    }

    .custom-profile-image-input {
      label {
        height: 110px;
        width: 110px;
      }
    }

    .date-picker-item {
      border: 0 !important;
      border-radius: 0.4rem;
      position: relative;

      .date-picker-label {
        font-size: 12px;
        position: absolute;
        top: -10px;
        left: 5px;
        padding: 0 5px;
        background-color: #ffffff;
        z-index: 1;
      }

      .vpd-input-group {
        width: 20px !important;
        position: absolute;
        top: 6px;
        left: 2px;

        .vpd-icon-btn {
          opacity: 1 !important;
          height: 25px !important;
          width: 20px !important;
          background-color: inherit !important;

          svg {
            fill: #000000;
            overflow: visible !important;
          }
        }

        .form-control {
          border: 0;
          width: 100%;
          display: none;
          line-height: 35px;
        }
      }
    }

    .input-field-item {
      position: relative;
      border: 1px solid #cdcdcd;
      border-radius: 0.4rem;
      padding: 5px 10px;
      margin: 15px 0;
      display: flex;
      justify-content: space-around;
      min-height: 35px;

      &.invalid {
        border-color: #b00000;

        .input-field-label {
          color: #b00000;
        }
      }

      .input-field-label {
        position: absolute;
        font-size: 12px;
        top: -10px;
        left: 8px;
        background-color: #ffffff;
        padding: 0 5px;
      }

      .radio-item {
        display: flex;
        align-items: center;
        direction: rtl;

        label {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
